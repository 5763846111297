@import "_variables.scss";

$gdpr-color-theme: #0173d7;
$gdpr-toast-position: left;

@import "~@3technologysrl/front-gdpr/gdpr-module";

html body {
  font-family: 'Raleway';
}

.pre-header{
  display: none;

  @include media-sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:#363636;
    color:white;
    .fixed & {
      display:none;
    }
    ul{
      display: flex;
      align-items: center;
      margin:0;
      padding:0;
      &.i-contact {
        justify-content: flex-start;
        margin-left: 15px;
        @include media-xmd {
          margin-left: 50px;
        }

      }
      &.i-social {
        justify-content: flex-end;
        margin-right: 15px;
        @include media-xmd {
          margin-right: 50px;
        }
        a {
          padding:4px 7px;
          font-size: 15px;
        }
      }
      li {
        list-style: none;
        padding:5px 10px;
        margin:0 5px 0 0;
        display: flex;
      }
    } 
  }
}
// increase padding-top for preheader
@include media-sm {
  .page-title.page-title-1{
      padding-top: calc(86px + 35px);
  }
}
header{
    #nav-bar{
        display: flex;
        .logo-wrapper{
            display: flex;
            svg {
                margin-top:15px;
            }

            .copy-logo {
                display: flex;
                flex-direction: column;
                strong {
                    font-size: 24px;
                    font-weight: 600;
                    color:#515151;
                    display: block;
                    line-height: 1;
                    margin: 16px 5px 3px 7px;
                    letter-spacing: 0.7px;
                }
                span {
                    font-size: 12px;
                    font-weight: 500;
                    color:#9D9D9D;
                    line-height: 1;
                    font-family: "Open Sans";
                    letter-spacing: -0.6px;
                    margin: 0 3px 0 7px;
                }

            }
        }
        a{
          &.highlight {
            
            span {
              
              margin: 0 auto;
              display: block;
              text-align: center;
              max-width: 500px;
              color:#fff;
              background-color: #007DE0;
              padding:15px 30px;
              border-radius:2px;
              @include media-xmd {
                margin: 0;
                display: inline;
              }
              
            }
          }
        }
    }
    .module-group {
        margin-left: auto;
        #nav-primary {
          padding:0;
          a {
            padding: 23px 20px;
            &:active {
              background-color: #e9e9e9;
            }
            @include media-xmd {
              padding: 0 13px;
            }
          }
        }
    }
}

footer {
  &.bg-secondary {
    background-color:#000;
  }
  .footer-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @include media-md {
      flex-direction: column;
    }
    /*
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      span > span {
        display: inline-block;
      }
      @include media-md {
        text-align: left;
        span {
          display: block;
          span {
            display: inline-block;
          }
        }
      }
    }*/

    .logo,
    .slogan,
    .newsletter-social,
    .sitemap {
      max-width: 400px;
      margin:0 auto;
      width: 100%;
      @include media-md {
        margin:0;
      }
    }

    .logo {
      font-size:13px;
      display: flex;
      font-family: "Open Sans";
      align-items: center;
      color:#ccc;
      letter-spacing: -0.1px;
      svg {
        margin-right: 13px;
        margin-bottom: 0;
        min-width: 58px;
      }
      b {
        color:#fff;
        font-size:22px;
        line-height: 25px;
        font-family: "Montserrat";
        letter-spacing: -0.1px;
        font-weight: 600;
      }
    }
    .slogan {
      margin-top:24px;
      font-style: italic;
      font-size:15px;
      line-height: 1.2;
      letter-spacing: -0.5px;
      color:#ccc;
      &:before {
        content:"“";
        font-size:18px;
        font-family: 'Times New Roman', Times, serif;
      }
      &:after {
        content:"”";
        font-size:18px;
        font-family: 'Times New Roman', Times, serif;
      }
    }
    h5 {
      font-size: 16px;
      font-weight: 700;
      color:#fff;
    }
    ul {
      
      padding:0;
      li {
        list-style: none;
        margin:0;
        padding:3px 10px 3px 0;
        font-size: 15px;
        display: flex;
        align-items: center;
      }
    }
    form {
      display: flex;
      margin-top:10px;
      margin-bottom:10px;
      width: 100%;
      input {
        width: 100%;
        max-width: 280px;
      }
      button {
        margin-left: 10px;
        width: auto;
        margin-bottom:0;
      }
    }
    .i-social {
      a {
        font-size: 24px;
        padding:5px 16px 5px 0;
        margin:0 7px;
      }
    }
    &.footer-block-3 {
      ul {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          width: 100%;
        }
      }
    }
  }
  .end-row {
    span {
      display: inline-block;
    }
  }
}

body.header-absolute #content {
  .home-hero-slider {
    &:first-child {
      padding-top: 100px;
      padding-bottom:10px;
      padding-left: 15px;
      padding-right: 15px;
      @include media-sm {
        padding-top: 140px;
        padding-bottom:40px;
      }
      @include media-md {
        padding-top: 140px;
        padding-bottom:90px;
      }
    }
    h1.text-xl {
      font-size:32px;
      line-height: 1.2;
      margin: 15px auto;
      font-weight: 500;
      padding-bottom: 15px;
      span {
        font-size:24px;
        display: block;
        line-height: 1.2;
        margin: 15px auto;
        font-weight: 500;
        max-width: 90%;
      }
      @include media-sm {
        font-size:60px;
        span {
          font-size:34px;
          margin: 25px auto;
        }
      }
      @include media-md {
        font-size:72px;
        max-width: 1200px;
        line-height: 1.1;
        margin:20px auto 0 auto;
        span {
          font-size:44px;
        }
      }
    }
  }

  .home-hero-video {
    .op-layer {
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      //background-image: linear-gradient(180deg, #0057A3 0%, #0173D7 100%);
      background-image: linear-gradient(0deg, #0173d7f2 0%, #0057a305 50%);
      background-size: cover;
      
      position: absolute;
      padding: 0;
      margin: 0;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .btn-filled.btn-primary {
      //border-bottom:1px solid rgba(255,255, 255, 0.2);
      //border-right:1px solid rgba(255,255, 255, 0.2);
      border-top:1px solid rgba(255,255, 255, 0.2);
      border-left:1px solid rgba(255,255, 255, 0.2);
      box-shadow: 3px 5px 6px 1px rgb(0 82 155 / 52%);
    }
  }

}

.page-title.page-title-1 {
    padding-bottom:0;
    overflow: initial;
    z-index: 1;
    border-bottom: 50px solid white;
    /* with 3 elements
    background-position-y: bottom, top, center;
    background-position-x: 105%, 102%, center;
    background-repeat: no-repeat, no-repeat, repeat;
    */
    // with 2 elements 
    background-position-y: bottom, center;
    background-position-x: 105%, center;
    background-repeat: no-repeat, repeat;
    &.vector-blue {
        background-size: contain, cover;
        background-image: 
        url('https://static.3technology.it/img/img-header1.png'),
        //url("data:image/svg+xml;utf8,<svg width='311' height='245' xmlns='http://www.w3.org/2000/svg'><path d='M275.039 69.423 245.258 52.21V17.79L275.038.577 304.82 17.79V52.21l-29.781 17.212Zm-139.862 0-29.78-17.212V17.79L135.176.577l29.781 17.212V52.21l-29.78 17.212Zm69.931 0-29.78-17.212V17.79L205.107.577 234.89 17.79V52.21l-29.78 17.212Zm-139.862 0L35.466 52.21V17.79L65.245.577 95.027 17.79V52.21l-29.78 17.212Zm174.827 58.676-29.78-17.211V76.465l29.78-17.211 29.781 17.211v34.423l-29.78 17.211Zm69.431-.289-29.28-16.922V76.465l29.28-16.922v68.267Zm-209.292.289L70.43 110.888V76.465l29.78-17.211 29.782 17.211v34.423l-29.781 17.211Zm69.93 0-29.78-17.211V76.465l29.78-17.211 29.782 17.211v34.423l-29.781 17.211Zm-139.861 0L.5 110.888V76.465l29.78-17.211 29.782 17.211v34.423L30.28 128.099Zm244.758 58.162-29.781-17.211v-34.423l29.78-17.211 29.782 17.21v34.424l-29.781 17.21Zm-139.862 0-29.78-17.211v-34.423l29.78-17.211 29.781 17.21v34.424l-29.78 17.21Zm69.931 0-29.78-17.211v-34.423l29.78-17.211 29.781 17.21v34.424l-29.78 17.21Zm-139.862 0-29.78-17.211v-34.423l29.78-17.211 29.781 17.21v34.424l-29.78 17.21Zm34.966 58.162L70.43 227.21V192.79l29.78-17.212 29.782 17.212v34.422l-29.781 17.212Zm-69.931 0L.5 227.21V192.79l29.78-17.212 29.782 17.212v34.422L30.28 244.423Zm139.862 0-29.781-17.212V192.79l29.78-17.212 29.782 17.212v34.422l-29.781 17.212Zm69.93 0-29.78-17.212V192.79l29.78-17.212 29.781 17.212v34.422l-29.78 17.212Zm70.427-.29-29.28-16.922V192.79l29.28-16.923v68.268Z' stroke='%23FFF' fill='none' fill-rule='evenodd' opacity='.239'/></svg>"),
        linear-gradient(180deg, #0057A3 0%, #0173D7 100%);
    }
    &.vector-gray {
      background-size: contain, cover;
      background-image: 
        url('https://static.3technology.it/img/img-header1.png'),
        //url("data:image/svg+xml;utf8,<svg width='311' height='245' xmlns='http://www.w3.org/2000/svg'><path d='M275.039 69.423 245.258 52.21V17.79L275.038.577 304.82 17.79V52.21l-29.781 17.212Zm-139.862 0-29.78-17.212V17.79L135.176.577l29.781 17.212V52.21l-29.78 17.212Zm69.931 0-29.78-17.212V17.79L205.107.577 234.89 17.79V52.21l-29.78 17.212Zm-139.862 0L35.466 52.21V17.79L65.245.577 95.027 17.79V52.21l-29.78 17.212Zm174.827 58.676-29.78-17.211V76.465l29.78-17.211 29.781 17.211v34.423l-29.78 17.211Zm69.431-.289-29.28-16.922V76.465l29.28-16.922v68.267Zm-209.292.289L70.43 110.888V76.465l29.78-17.211 29.782 17.211v34.423l-29.781 17.211Zm69.93 0-29.78-17.211V76.465l29.78-17.211 29.782 17.211v34.423l-29.781 17.211Zm-139.861 0L.5 110.888V76.465l29.78-17.211 29.782 17.211v34.423L30.28 128.099Zm244.758 58.162-29.781-17.211v-34.423l29.78-17.211 29.782 17.21v34.424l-29.781 17.21Zm-139.862 0-29.78-17.211v-34.423l29.78-17.211 29.781 17.21v34.424l-29.78 17.21Zm69.931 0-29.78-17.211v-34.423l29.78-17.211 29.781 17.21v34.424l-29.78 17.21Zm-139.862 0-29.78-17.211v-34.423l29.78-17.211 29.781 17.21v34.424l-29.78 17.21Zm34.966 58.162L70.43 227.21V192.79l29.78-17.212 29.782 17.212v34.422l-29.781 17.212Zm-69.931 0L.5 227.21V192.79l29.78-17.212 29.782 17.212v34.422L30.28 244.423Zm139.862 0-29.781-17.212V192.79l29.78-17.212 29.782 17.212v34.422l-29.781 17.212Zm69.93 0-29.78-17.212V192.79l29.78-17.212 29.781 17.212v34.422l-29.78 17.212Zm70.427-.29-29.28-16.922V192.79l29.28-16.923v68.268Z' stroke='%23FFF' fill='none' fill-rule='evenodd' opacity='.239'/></svg>"),
        linear-gradient(180deg, #455058 0%, #69757D 100%);
	}
	
    .container {
      max-width: none;
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      @include media-xmd {
        padding-left: 30px;
        padding-right: 30px;
      }
      @media screen and (min-width: 1199px){
        padding-left: 50px;
        padding-right: 50px;
      }
	}
	
    .breadcrumb {
      float:none;
      margin-top:0;
      transform: translateY(35px);
      color:#434343;
      font-weight: 500;
    }
}

.separator-row {
    padding-top: 50px;
    text-align: center;
    padding-bottom: 50px;
    h2 {
      margin-bottom:25px;
    }
    .container {
        max-width: 750px;
        p.lead {
          font-size: 18px;
        }
        .btn.btn-filled {
          @media screen and (max-width:340px){
            padding:13px 14px 15px;
            font-size: 14px;
            letter-spacing: -0.3px;
          }
        }
    }

}

.contact-separator {
  padding:40px 30px;
  @include media-md {
    padding:90px 30px;
  }
  form {
    display: block;
    margin: 0 auto;
    max-width: 440px;
    @include media-sm {
      max-width: none;
    }
  }
  input {
    margin-bottom:10px !important;
    @include media-md {
      margin-bottom:5px !important;
    }
  }
  button {
    width: 100%;
    margin-top: 10px;
    @include media-md {
      margin-top: 0;
    }
    span {
      display:inline-block;
    }
    @include media-md {
      span {
        display:none;
      }
      width: auto;
      font-size: 12px !important;
    }
  }
  p {
    font-size: 18px;
    a {
      color:white;
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

// image technologies hex
.hex-desktop{
  display: none;
  @include media-sm {
    display: block;
  }
}
.hex-mobile{
  display: block;
  margin:0 auto;
  @include media-sm {
    display: none;
  }
}

// section double 
@media only screen and (min-width: 992px) {
  .row-double.left {
    .image {
      left:0;
    }
    .content{
      transform: translateX(100%);
    }
  } 
}

.section-double {

  .row {
    @media screen and (max-width: 991px) {  
      display: flex;
      flex-direction: column;
    }
  }

  &.right {
	  .content {padding: 7%; padding-left: 0%;}
	  p.lead {line-height: 1.8 !important;}
  }
  &.left {
	.content {padding: 7%; padding-right: 0%}
	p.lead {line-height: 1.8 !important;}
}

  .image{
	min-height: 100%;
  }

  @media screen and (min-width: 992px) {
    position: relative;
    overflow: inherit;
    height: auto;
    &:before {
      content:"";
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: 
        url("data:image/svg+xml;utf8,<svg width='109' height='109' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><path id='a' d='m44.564 0 38.593 22.282v44.563L44.564 89.127 5.97 66.845V22.282z'/></defs><g fill='none' fill-rule='evenodd'><path fill='%23FFF' d='m54.5 0 47.198 27.25v54.5L54.5 109 7.302 81.75v-54.5z'/><g transform='translate(10.238 10.238)'><mask id='b' fill='%23fff'><use xlink:href='%23a'/></mask><use fill='%232686C9' xlink:href='%23a'/><path fill='%230173D7' mask='url(%23b)' d='m82.223 21.759 38.593 22.281v44.564l-38.593 22.282L43.63 88.604V44.04z'/><path fill='%230061B6' mask='url(%23b)' d='M5.23 21.759 43.824 44.04v44.564L5.23 110.886l-38.593-22.282V44.04z'/></g></g></svg>");
      width: 109px;
      height: 109px;
      z-index: 1;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
    &.last {
      &:before {
        display: none;
      }
    }
  }

  .container {
    max-width: 1440px;
	width: 100%;

	&.custom-full-width {
		max-width: unset;
		width: 100%;
		min-height: 500px;

		.content {
			padding: 7%;	

			.lead {
				line-height: 1.8;
			}		
		}
	}
    h2 {
      font-weight: 500;
      margin-bottom:15px;
    }
    p.lead {
      font-size:18px;
      line-height: 1.5;
      margin-bottom:10px;
      b {
        font-weight: 600;
      }
    }
    .btn.btn-filled{
      margin-top: 10px;
      @media screen and (max-width: 991px) {  
        margin-bottom: 30px;
      }
    }
  }

  .content{
    transform: none;
	padding: 30px 6%;
	padding: 7%;
	
    @media screen and (max-width: 991px) {  
      order: 2;
    }
    @include media-md {
	  padding:50px 6%;
	  padding: 7%;
    }
    .long-text {
      @media screen and (max-width:399px){
        padding:13px 14px 15px;
        font-size: 14px;
        letter-spacing: -0.3px;
      }
      @media screen and (max-width:360px){
        padding:13px 8px 15px;
        font-size: 14px;
        letter-spacing: -0.3px;
      }
    }
  }
  .image{
    max-height: 190px;
    @media screen and (max-width: 991px) {  
      order: 1;
    }
    @include media-sm {
      max-height: 310px;
    }
    @include media-md {
      max-height: 410px;
    }
    @include media-xmd {
      max-height: 500px;
    }
    @include media-lg {
      max-height: 580px;
    }
  }
}

// section history
.history-container {
    margin:0 auto;
    background:#f8f8f8;
    background-repeat:no-repeat;
    background-position:top left, bottom right;
    background-image:
      url("data:image/svg+xml;utf8,<svg width='628' height='263' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><path id='a' d='M0 0h628v263H0z'/></defs><g fill='none' fill-rule='evenodd'><mask id='b' fill='%23fff'><use xlink:href='%23a'/></mask><g mask='url(%23b)'><path fill='%23EEE' d='m204-52 58.89 34v68L204 84l-58.89-34v-68zM68-52l58.89 34v68L68 84 9.11 50v-68zM340-52l58.89 34v68L340 84l-58.89-34v-68zM476-52l58.89 34v68L476 84l-58.89-34v-68z'/><path fill='%23F2F2F2' d='m140 59 58.89 34v68L140 195l-58.89-34V93zM0 59l58.89 34v68L0 195l-58.89-34V93z'/><path fill='%23F4F4F4' d='m272 59 58.89 34v68L272 195l-58.89-34V93z'/><path fill='%23F7F7F7' d='m408 59 58.89 34v68L408 195l-58.89-34V93z'/><path fill='%23FFF' d='m544 59 58.89 34v68L544 195l-58.89-34V93z'/></g></g></svg>"),
      url("data:image/svg+xml;utf8,<svg width='628' height='263' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><path id='a' d='M0 0h628v263H0z'/></defs><g transform='rotate(180 314 131.5)' fill='none' fill-rule='evenodd'><mask id='b' fill='%23fff'><use xlink:href='%23a'/></mask><g mask='url(%23b)'><path fill='%23EEE' d='m204-52 58.89 34v68L204 84l-58.89-34v-68zM68-52l58.89 34v68L68 84 9.11 50v-68zM340-52l58.89 34v68L340 84l-58.89-34v-68zM476-52l58.89 34v68L476 84l-58.89-34v-68z'/><path fill='%23F2F2F2' d='m140 59 58.89 34v68L140 195l-58.89-34V93zM0 59l58.89 34v68L0 195l-58.89-34V93z'/><path fill='%23F4F4F4' d='m272 59 58.89 34v68L272 195l-58.89-34V93z'/><path fill='%23F7F7F7' d='m408 59 58.89 34v68L408 195l-58.89-34V93z'/><path fill='%23FFF' d='m544 59 58.89 34v68L544 195l-58.89-34V93z'/></g></g></svg>"),
      linear-gradient(180deg, #f8f8f8 0%, #FFFFFF 49%, #f7f7f7 100%);
      
    h1 {
        padding-top:50px;
        text-align:center;
    }
    .timeline-block {
      position:relative;
      max-width:800px;
      margin:0 auto;
  
      &:before {
        content:"";
        position:absolute;
        top:355px;
        bottom:145px;
        width:20px;
        background:#99CBF2;
        left:calc(50% - 67px);
        border-radius:20px;
        z-index:0;
        @media only screen and (max-width: 767px) { 
            left: 62px;
            top: 120px;
        }
      }
      &:after {
        content:"";
        position:absolute;
        top:115px;
        bottom:355px;
        width:20px;
        background:#99CBF2;
        left:calc(50% + 47px);
        border-radius:20px;
        z-index:0;
        @media only screen and (max-width: 767px) { 
            display: none;
        }
      }
      .timeline-block-row {
        display:flex;
        justify-content:space-around;
        align-items:center;
        z-index:1;
        position:relative;
        
        &:not(:first-child){
          border:0px solid black;
          margin-top:-30px;
          @include media-mobile {
            margin-top:-100px;
          }
        }
        &:last-child{
          padding-bottom:50px;
        }
        .timeline-info {
          border:0px solid blue;
          width:45%;
          padding:15px 20px;
          
          @media only screen and (max-width: 767px) { 
            width: 100%;
            order: 2;
          }   
          
          h3 {
            font-size:44px;
            margin:0;
            padding:0;
            font-weight: 600;
            @media only screen and (max-width: 767px) { 
                font-size:30px;
            }
          }
          h4 {
            font-size:24px;          
            margin:0 0 10px 0;
            padding:0;
            font-weight: 500;
            letter-spacing: -0.5px;
            @media only screen and (max-width: 767px) { 
                font-size:18px;
                letter-spacing: -0.1px;
            }
          }
          p {
            font-size:16px;
            margin:10px 0;
            padding:0;
            line-height: 1.5;
            @media only screen and (max-width: 767px) { 
                font-size:15px;
                letter-spacing: -0.1px;
            }
          }
        }
        .timeline-ex {
          width:60%;
          text-align:right;
          @media only screen and (max-width: 767px) { 
            width: 40%;
            order: 1;
            max-width: 130px;
          }   
          img {
            width:231px;
            height:265px;
            margin-right:-3px;
            max-width:100%;
            @media only screen and (max-width: 767px) { 
                transform: scale(0.5);
                max-width: none;
                transform-origin: left;
                margin:0 auto 0 15px !important;
            }
            
          }
        }
        
        &.odd {
          .timeline-info {
            border:0px solid green;
            text-align:right;
            @media only screen and (max-width: 767px) { 
                text-align:left;
            }
  
          }
          .timeline-ex {
            border:0px solid pink;
            text-align:left;
            svg {
              margin-left:-3px;
              
            }
          }
        }
      }
    }
  }

  .values-home {
    padding:40px 0;
    @include media-md{
      padding:90px 0;
    }
    .c-subtitle {
      margin-bottom: 40px;
      @include media-md{
        margin-bottom:90px;
      }
    }
    background-color: #f6f6f6;
      &:before {
        content:"";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: -1px;
        z-index: 0;
        display: block;
        @media screen and (min-width:992px) {
          background-position-y: bottom, center;
          background-position-x: right, center;
          background-repeat: no-repeat, repeat;
          background-image:
            url("data:image/svg+xml;utf8,<svg width='353' height='169' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><linearGradient x1='18.691%' y1='41.757%' x2='80.705%' y2='68.126%' id='c'><stop stop-color='%23455058' offset='0%'/><stop stop-color='%23455058' stop-opacity='.147' offset='100%'/></linearGradient><path id='a' d='M0 0h353v169H0z'/></defs><g transform='rotate(180 176.5 84.5)' fill='none' fill-rule='evenodd'><mask id='b' fill='%23fff'><use xlink:href='%23a'/></mask><g mask='url(%23b)' fill='url(%23c)'><path d='m106.375 105.761 20.894 12v24l-20.894 12-20.894-12v-24l20.894-12Zm-69.765 0 20.894 12v24l-20.894 12-20.893-12v-24l20.893-12Zm35-67.239 30.679 17.62V91.38L71.61 109 40.932 91.38V56.143l30.678-17.62Zm-69.932 0 30.679 17.62V91.38L1.678 109-29 91.38V56.143l30.678-17.62Zm139.697 0 30.678 17.62V91.38L141.375 109l-30.678-17.62V56.143l30.678-17.62Zm70.849.24 20.894 12v24l-20.894 12-20.894-12v-24l20.894-12Zm71.151 6 14.8 8.5v17l-14.8 8.5-14.8-8.5v-17l14.8-8.5ZM105.951-27l30.678 17.62v35.238l-30.678 17.62-30.678-17.62V-9.38L105.95-27Zm-68.849 0L67.781-9.38v35.238l-30.679 17.62-30.678-17.62V-9.38L37.102-27ZM176.8-27l30.679 17.62v35.238l-30.679 17.62-30.678-17.62V-9.38L176.8-27Zm70.849 0 30.678 17.62v35.238l-30.678 17.62-30.678-17.62V-9.38L247.648-27Zm70.674 0L349-9.38v35.238l-30.678 17.62-30.679-17.62V-9.38L318.322-27Z'/></g></g></svg>"),
            linear-gradient(180deg, rgba(27,154,255,0) 0%, rgba(27,154,255,0) 56%, #373e42 56%, #373e42 100%);
        }
        
        @media screen and (min-width:1200px) {
          background-image:
            url("data:image/svg+xml;utf8,<svg width='353' height='169' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><linearGradient x1='18.691%' y1='41.757%' x2='80.705%' y2='68.126%' id='c'><stop stop-color='%23455058' offset='0%'/><stop stop-color='%23455058' stop-opacity='.147' offset='100%'/></linearGradient><path id='a' d='M0 0h353v169H0z'/></defs><g transform='rotate(180 176.5 84.5)' fill='none' fill-rule='evenodd'><mask id='b' fill='%23fff'><use xlink:href='%23a'/></mask><g mask='url(%23b)' fill='url(%23c)'><path d='m106.375 105.761 20.894 12v24l-20.894 12-20.894-12v-24l20.894-12Zm-69.765 0 20.894 12v24l-20.894 12-20.893-12v-24l20.893-12Zm35-67.239 30.679 17.62V91.38L71.61 109 40.932 91.38V56.143l30.678-17.62Zm-69.932 0 30.679 17.62V91.38L1.678 109-29 91.38V56.143l30.678-17.62Zm139.697 0 30.678 17.62V91.38L141.375 109l-30.678-17.62V56.143l30.678-17.62Zm70.849.24 20.894 12v24l-20.894 12-20.894-12v-24l20.894-12Zm71.151 6 14.8 8.5v17l-14.8 8.5-14.8-8.5v-17l14.8-8.5ZM105.951-27l30.678 17.62v35.238l-30.678 17.62-30.678-17.62V-9.38L105.95-27Zm-68.849 0L67.781-9.38v35.238l-30.679 17.62-30.678-17.62V-9.38L37.102-27ZM176.8-27l30.679 17.62v35.238l-30.679 17.62-30.678-17.62V-9.38L176.8-27Zm70.849 0 30.678 17.62v35.238l-30.678 17.62-30.678-17.62V-9.38L247.648-27Zm70.674 0L349-9.38v35.238l-30.678 17.62-30.679-17.62V-9.38L318.322-27Z'/></g></g></svg>"),
            linear-gradient(180deg, rgba(27,154,255,0) 0%, rgba(27,154,255,0) 58%, #373e42 58%, #373e42 100%);
        }
        
        
      }

      .feature {
        @media screen and (min-width:992px) {
          color:#fff;
        }
        h5 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom:5px;
        }
        p {
          font-size: 18px;
          @media screen and (min-width:992px) {
            color:#c7c7c7;
          }
        }
      }
  }

  .our-values {
    padding:40px 0;
    @include media-md{
      padding:90px 0;
    }
    .feature {
      &.feature-2 .icon {
        margin-top:10px;
      }
      h5 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 15px;
      }
      p {
        font-size: 18px;
        
      }
    }
  }
  
  #services {
    padding:40px 0;
    @include media-md{
      padding:90px 0;
    }
    background:#f8f8f8;
    background-repeat:no-repeat;
    background-position:bottom right;
    background-image:
      url("data:image/svg+xml;utf8,<svg width='628' height='263' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><path id='a' d='M0 0h628v263H0z'/></defs><g transform='rotate(180 314 131.5)' fill='none' fill-rule='evenodd'><mask id='b' fill='%23fff'><use xlink:href='%23a'/></mask><g mask='url(%23b)'><path fill='%23EEE' d='m204-52 58.89 34v68L204 84l-58.89-34v-68zM68-52l58.89 34v68L68 84 9.11 50v-68zM340-52l58.89 34v68L340 84l-58.89-34v-68zM476-52l58.89 34v68L476 84l-58.89-34v-68z'/><path fill='%23F2F2F2' d='m140 59 58.89 34v68L140 195l-58.89-34V93zM0 59l58.89 34v68L0 195l-58.89-34V93z'/><path fill='%23F4F4F4' d='m272 59 58.89 34v68L272 195l-58.89-34V93z'/><path fill='%23F7F7F7' d='m408 59 58.89 34v68L408 195l-58.89-34V93z'/><path fill='%23FFF' d='m544 59 58.89 34v68L544 195l-58.89-34V93z'/></g></g></svg>"),
      linear-gradient(180deg, #f8f8f8 0%, #FFFFFF 49%, #f7f7f7 100%);
    .feature {
      h5 {
        font-weight: 700;
        font-size: 22px;
        letter-spacing: -0.4px;
      }
      p {
        color:#787878;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1.3;
      }
      &.feature-1 .icon {
        margin-bottom: 5px;
        font-size: 22px;
        line-height: 1;
        width: auto;
      }
    }
    .btn.btn-filled {
      @media screen and (max-width:340px){
        padding:13px 18px 15px;
        font-size: 14px;
        letter-spacing: -0.3px;
      }
    }
  }

  #contact-home {
    padding:40px 0;
    @include media-md{
      padding:90px 0;
    }
    .feature {
      margin-bottom:50px;
      &:last-child {
        margin-bottom:0px;
      }
      @include media-md{
        margin-bottom:30px;
      }
      .icon {
        margin-bottom: 0px;
        line-height: 1;
        font-size: 70px;
        @include media-md{
          margin-bottom: 10px;
          font-size: 82px;
        }
      }
      address{
        font-size:22px;
        font-weight: 500;
        strong {
          font-size: 18px;
          font-weight: 400;
        }
        a {
          color:#017de0;
          @include media-md{
            color:inherit;
          }
        }
      } 
    }
  }



.services-home {
  padding:40px 0;
  @include media-md{
    padding:90px 0;
  }
  background:#373e42;
  .col-md-4 {
    @include media-xmd {
      min-height: 250px;
    }
  }
  .feature{
    .icon {
      font-size: 44px;
      color:#72c5ff;
      font-weight: 700;
      line-height: 1;
      svg {
        g, 
        ellipse,
        path {
          fill:#fff;
        }
      }
    }
    .feature-content{
      padding-left: 66px !important;
      h5 {
        font-weight: 700;
        font-size: 21px;
        letter-spacing: -0.5px;
        margin-bottom:15px;
        &:before {
          position: absolute;
          content: " ";
          border-bottom: 2px solid #ffffff8a;
          width: 30px;
          height: 30px;
        }
      }
      p {
        color:#c7c7c7;
        font-size: 18px;
      }
    }
  } 
}

// styles overwrite dialog
ctv-dialog-popup {
  .ctv-dialog-shadow{
    z-index:1000;
  }
}
ctv-legal-dialog,
ctv-contact-us-dialog {
  div.ctv-dialog-shadow.modal {
    z-index:999;

    h2,
    h3 {
      font-size:24px;
      font-weight: 600;
      font-family: 'Open Sans';
    }
  }
}
ctv-contact-us-dialog {
  div.ctv-dialog-shadow.modal {
    div.ctv-dialog-wrapper{
      @include media-sm {
        max-height: 720px
      }
      // forced X dialog close desktop-mobile START
      div.ctv-dialog-close{
        display: block;
      }
      div.ctv-dialog-header{
        position: absolute;
        display: none;
        @include media-sm {
          display: block;
          position: relative;
        }
        // forced X dialog close desktop-mobile END
        .arrow-container a {
          @include media-sm {
            display: none;
          }
        }
        .title {
          @include media-sm {
            display: none;
            background:#007DE0;
            border-radius: 2px;
          }
        }
      } 
      div.ctv-dialog-body {
        max-height: unset;
        height: calc(100vh - 30px);
        @include media-sm {
          height: calc(100vh - 106px);
        }

        form {
          .form-row {
            > div {
              &:not(.ctv-input-field) {
                margin-bottom: 0;
              }
            }
            label {
              display: none;
            }
            input,
            textarea {
              padding: 15px 20px;
              font-family: Arial, Helvetica, sans-serif;
            }
            textarea {
              height: auto;
              min-height: 180px;
              max-height: none;
              resize: vertical;
            }
          }
        }

        .box-title {
          margin:30px auto 20px auto;
          p {
            font-size:16px;
            margin:10px auto 20px;
            text-align: center;
          }
        }
        .btn {
          background: #007DE0;
          color:#fff;
          border:0;
          outline: none;
          font-size: 15px;
          &:hover {
            background:#1f6a9e;
            //opacity: 0.8;
          }
        }
        .button-set {
          width: 100%;
          text-align:center;
          margin-bottom:20px;
          .btn {
            margin:0 auto;

            &.loading {
              > * {
                visibility: hidden;
              }
            
              &::after {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                border: 4px solid transparent;
                border-top-color: #ffffff;
                border-right-color: #FFF;
                border-radius: 50%;
                animation: button-loading-spinner 1s ease infinite;
              }
            }
          }
        }

        .disclaimer {
          margin-top: 20px;
          width: 100%;
          padding-bottom: 30px;
        }

        .options {
          display: flex;
          flex-wrap: wrap;

          .ctv-input-field {
            width: auto;
            flex: auto;
          }
        }

        .accept-legal{
          display: block;
          text-align: left;
          font-size:13px;
          color:#545454;
          padding:5px 10px;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;

          input[type="radio"], input[type="checkbox"]{
              display:inline;
              width:16px;
              height:16px;
              border-radius:3px;
              border:1px solid #ccc;
              vertical-align: bottom;
              margin-bottom: 2px;
              margin-right: 3px;
          }
          
          label {
              font-size:13px;
              color:#545454;
              display:inline;
              vertical-align: bottom;
              font-weight: normal;
          }
          
          a {
              font-size:13px;
              color:var(--input-blue-label);
              display:inline;
              vertical-align: bottom;
          }
          .error {
              color: var(--input-color-error);
              visibility: hidden;
          }

          &.error {
            .error {
              visibility: visible;
            }            
          }
        }
      }
      div.ctv-dialog-footer {
        display: none;
      }
    } 
  }
}
// styles overwrite dialog

// themes overwrites
p b,
b {
  font-weight: 500;
}
.btn,
.form-control.input-2,
.btn-submit:after {
  border-radius:2px;
}
.btn.btn-filled {
  font-size: 15px;
}
input[type=text].form-control, 
input[type=email].form-control, 
textarea {
  padding:15px 20px;
}
.btn.btn-primary,
.btn-submit:after {
  border-color: #007DE0;
}
.btn-filled.btn-primary,
.btn-submit:after {
  background-color: #007DE0;
}

.lead {
  font-weight: 400;
}

.c-title {
  font-weight: 500;
}
.c-subtitle {
  font-size: 24px;
  line-height: 1.5;
}

//overwrites front-base-css {
:root {
  --bg-color-orange: #007DE0 !important;
  --color-primary--medium: #007DE0 !important;
}

.container-xxl {
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
}

.container {
	&.job-listing-container {
		background-color: #ffffff;
    margin-bottom: 50px;
    @include media-md {
		  border: 1px solid #cccccc;
      box-shadow: 0 8px 14px 3px rgb(0 0 0 / 10%);
    }
    
		.job-listing-container-row {
      padding: 0 20px;
      @include media-md {
        padding: 0 50px;
      }
		}

		.section-title {
			font-size: 36px;
			font-weight: 500;
			margin-bottom: 15px;
		}
		
		.section-description {
			font-size: 18px;
			margin-bottom: 15px;
		}

		.job-listing {
			border-bottom: 1px solid #cccccc;
      padding: 30px 0;
      &:last-child {
        border-bottom: 0;
      }
 

			.section-title {
				font-size: 36px;
				font-weight: 500;
    			margin-bottom: 15px;
			}
			.section-description {
				font-size: 18px;
				margin-bottom: 15px;
			}
	
			.job-title {	
        font-size: 20px;
        display: block;
        margin-bottom:10px;
        @include media-lg {
          margin-bottom:0;
        }

				a:hover {
					text-decoration: underline;
				}
			}
			.job-location {	
        font-size: 15px;
        margin-bottom:10px;
        display: inline-block;
        @include media-md {
          margin-bottom:0;
        }
			}
			.job-time {	
				font-size: 15px;
			}
		}
	}	
}


// button apply
.button-set-apply {

  &.apply-mobile {
    @media screen and (min-width:992px){
      display: none;
    }
  }
  .apply {
    display: block;
    height:54px;
    font-size:14px;
    width: 100%;
    @media screen and (min-width:992px){
      width: 100%;
    }
  }
  .back {
    display: none;
    height:54px;
    font-size:14px;
    width: 100%;
    @media screen and (min-width:992px){
      width: 100%;
    }
  }
  &.applying {
    .apply {
      display: none;
    }
    .back {
      display: block;
    }
  }
}

.job-item-container {
  background-color: #ffffff;
  margin-bottom: 50px;
  padding:30px;

  

  .jobs-viewport {
    padding-top: 30px;
    .jobs-content {
      position: relative;
      .jobs-description {
        display:block;
        transition: all 0.3s;
        opacity: 1;
      }
      .jobs-application{
        transition: all 0.3s;
        position:absolute;
        opacity: 0;
        transform: translateX(-100%);
        top:0;
      }

      &.applying {
        .jobs-description {
          display:none;
          opacity: 0;
        }
        .jobs-application{
          position:relative;
          opacity: 1;
          transform: translateX(0);
        }
      }

    }
  }

  @include media-md {
    border: 1px solid #cccccc;
    box-shadow: 0 8px 14px 3px rgb(0 0 0 / 10%);
  }

  .features {
    margin:10px 0 20px;
    display: block;
    @media screen and (min-width:992px){
      display: none;
    }
    .btn {
      float: right;
      padding: 13px 18px 15px;
    }
    .feature {
      margin-bottom: 5px;
      font-weight: 600;
      span {
        font-weight: 400;
        display: none;
        @include media-sm {
          display: inline-block;
        }
      }
    }
  }
  h3 {
    font-weight: 600;
  }
  p, ul  {
    font-size:15px;
  }
  ul {
    margin:15px 0 20px 0;
  }
  b {
    font-weight: 600;
  }
}
.job-item-apply-box {
  background-color: #ffffff;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  box-shadow: 0 8px 14px 3px rgb(0 0 0 / 10%);
  position:fixed;
  bottom:0px;
  left: 10px;
  right: 10px;
  z-index: 50;
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding:15px;

  @include media-sm {
    padding:30px;
  }
  
  @include media-xmd {
    position: relative;
    max-width: 293px;
    margin-left:-10px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .sticky-header & {
    @include media-xmd {
      position: fixed;
      top: 90px;
      left: auto;
      right: auto;
      bottom: auto;
      margin-left:0;
    }
  }
  h4 {
    margin-bottom: 0;
    font-size: 20px;
    display: none;
    @include media-sm {
      display: block;
    }
  }
  .share-job {
    margin:10px 0 20px;
    display: none;
    @include media-sm {
      display: flex;
    }
    a {
      display: flex;
      margin:5px 5px 5px 0;
      background-color: #f8f8f8;
      border:1px solid #ccc;
      padding:10px;
      width: 44px;
      height: 44px;
      border-radius: 44px;
      align-items: center;
      justify-content: center;
      i {
        font-size: 20px;
      }
    }
  }
  .apply-share{
    display: initial;
    width: 100%;
  }
  .features {
    display: block;
  }
  .feature {
    margin-bottom: 5px;
    @include media-sm {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: none;
      @include media-sm {
        display: block;
      } 
    }
    div {
      margin-top: 5px;
      font-weight: 600;
    }
  }
}

body {
  gdpr-toast {
    .toast-body{
      padding:7px 16px;
      p {
        font-size:16px;
      }
      .justify-content-end {
        padding:10px 0px 3px 10px !important;
        button.btn {
          font-size:16px;
          padding:7px 16px;
        }
      }
    }
  }

  manage-cookies-dialog {
    .modal-dialog {
      padding: 7px 16px;
      h2 {
        margin:10px 0;
      }
      p {
        font-size: 16px;
      }
      .modal-body {
        padding: 15px !important;
      }
      .modal-footer {
        padding: 10px !important;
        button.btn {
          font-size: 16px;
          padding: 7px 16px;
        }
      }
    }
  }
}