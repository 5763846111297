/////////////////////////////////////////////////////////////////////
// MEDIA QUERIES
/////////////////////////////////////////////////////////////////////
@mixin media-mobile {
	@media only screen and (max-width: 567px) { /* MOBILE */
		@content;
	}
}

@mixin media-sm {
	@media screen and (min-width: 568px) { /* SM */
		@content;
	}
}

@mixin media-md {
	@media screen and (min-width: 769px) { /* MD */
		@content;
	}
}

@mixin media-xmd {
	@media screen and (min-width: 991px) { /* g */
		@content;
	}
}

@mixin media-lg {
	@media screen and (min-width: 1024px) { /* LG */
		@content;
	}
}

@mixin media-xl {
	@media screen and (min-width: 1280px) { /* XL */
		@content;
	}
}